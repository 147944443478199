import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import(/* webpackChunkName: "about" */ '../views/index.vue'),
        children: [
            {
                path: '',
                name: 'home',
                meta: { title: '首页' },
                component: () => import('../views/Home')
            },
            {
                path: '/productSolutions',
                name: 'productSolutions',
                meta: { title: '智慧药学平台' },
                component: () => import(/* webpackChunkName: "about" */ '../views/productSolutions.vue')
            },
            {
                path: '/watchArea',
                name: 'watchArea',
                meta: { title: '区域审方平台、药政监管平台' },
                component: () => import(/* webpackChunkName: "about" */ '../views/watchArea.vue')
            },
            {
                path: '/about',
                name: 'about',
                component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
            },
            {
                path: '/CaseStudy',
                name: 'CaseStudy',
                meta: { title: '案例介绍' },
                component: () => import(/* webpackChunkName: "about" */ '../views/CaseStudy.vue')
            },
            {
                path: '/JoinUs',
                name: 'JoinUs',
                meta: { title: '加入我们' },
                component: () => import(/* webpackChunkName: "about" */ '../views/JoinUs.vue')
            },
            {
                path: '/businessDynamic',
                name: 'businessDynamic',
                meta: { title: '企业动态' },
                component: () => import(/* webpackChunkName: "about" */ '../views/businessDynamic.vue')
            },
            {
                path: '/joinUsDetails',
                name: 'joinUsdetails',
                meta: { title: '加入我们详情' },
                component: () => import(/* webpackChunkName: "about" */ '../views/joinUsDetails.vue'),
                props: true
            }
        ]
    },
    { path: '*', redirect: '/404' } //全不匹配的情况下，返回404，路由按顺序从上到下，依次匹配。最后一个*能匹配全部，
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

export default router
